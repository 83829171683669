<template>
  <ValidationObserver v-slot="{handleSubmit}">
    <page-loading :loading="initializing" />
    <div v-if="!initializing">
      <m-card :title="$t('shared.edit')" class="mb-4">
        <base-form v-model="model" />

        <template #actions>
          <page-footer edit @submit="handleSubmit(submit)" :loading="progressing" />
        </template>
      </m-card>

      <template v-if="$hasPermission('org.dashboard.operation')">
        <ActivityChart class="mb-3" :user-id="id" />
        <UvChart class="mb-3" :user-id="id" />
        <UvAlerts class="mb-3" :user-id="id" />
      </template>
    </div>
  </ValidationObserver>
</template>

<script>
import EditForm from './EditForm'
import ActivityChart from '@/pages/ORG/base/components/ActivityChart'
import UvChart from '@/pages/ORG/base/components/UvChart'
import UvAlerts from '@/pages/ORG/base/components/UvAlerts'

export default {
  name: 'Edit',
  props: ['id'],
  mixins: [EditForm],
  components: {
    ActivityChart,
    UvChart,
    UvAlerts
  },
  created() {
    this.getModel()
      .catch((err) => {
        this.$showError(err)
      })
      .finally(() => {
        this.initializing = false
      })
  },
  methods: {
    getModel() {
      const include = ['profile']
      return this.$api.org.users.getById(this.id, {include}).then((res) => {
        res.password = null
        res.passwordConfirm = null
        this.model = res

        this.$setBreadcrumbs([
          {text: this.$t('nav.users'), to: '/org/users'},
          {text: res.user.fullName, disabled: true}
        ])
      })
    },
    submit() {
      this.progressing = true
      return this.$api.org.users
        .update(this.id, this.model)
        .then((res) => {
          this.model = res
          this.$showSuccess(this.$t('users.userUpdated'))
        })
        .catch((err) => {
          this.$showError(err)
        })
        .finally(() => {
          this.progressing = false
        })
    }
  }
}
</script>
